import axios from "axios";

let jwt;

let baseURL = "";
let reAuthenticateURL = "";

if (window.location.origin === "http://localhost:3000") {
  baseURL = "http://localhost:8008";
  reAuthenticateURL = "https://www.caessia.com/";
} else {
  baseURL = "https://rigel.caessia.com";
  reAuthenticateURL = "https://login.caessia.com/";
}

// console.log(reAuthenticateURL);

const baseURLInstance = axios.create({
  baseURL: baseURL,
});

const reAuthenticateURLInstance = axios.create({
  baseURL: reAuthenticateURL,
});

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedRequestsQueue = [];
};

baseURLInstance.interceptors.request.use(
  async (config) => {
    const value = await localStorage.getItem("jwtToken");
    if (value) {
      jwt = JSON.parse(value);

      if (!config.headers.Authorization) {
        config.headers["Authorization"] = `Bearer ${jwt.access}`;
      }
    }
    // console.log(config);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseURLInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const newToken = await new Promise((resolve, reject) => {
            failedRequestsQueue.push({ resolve, reject });
          });
          console.log("newToken", newToken);
          originalRequest.headers["Authorization"] = "Bearer " + newToken;
          // console.log("originalRequest", originalRequest);

          return baseURLInstance(originalRequest);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const value = await localStorage.getItem("jwtToken");
        if (value) {
          jwt = JSON.parse(value);
          console.log("INRefresh", jwt);

          const response = await reAuthenticateURLInstance.post(
            "api/token/refresh/",
            { refresh: jwt.refresh },
            { headers: { Authorization: `Bearer ${jwt.refresh}` } }
          );

          if (response.data.access) {
            localStorage.setItem(
              "jwtToken",
              JSON.stringify({
                access: response.data.access,
                refresh: response.data.refresh,
              })
            );
            console.log("resDataINRefresh", response.data);
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access;
            processQueue(null, response.data.access);
            return baseURLInstance(originalRequest);
          }
        }

        // Handle refresh token failure
        processQueue(error, null);
        return Promise.reject(error);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

// API Calls
const getCrops = () => {
  return baseURLInstance.get("/api/crops/");
};

const getCrop = (id) => {
  return baseURLInstance.get(`/api/crops/${id}/`);
};

const getLines = (cropID) => {
  return baseURLInstance.get(`/api/crops/${cropID}/lines/`);
};

const getLine = (cropID) => {
  return baseURLInstance.get(`/api/lines/${cropID}/`);
};

const getTraits = () => {
  return baseURLInstance.get("/api/traits/");
};

const getTrait = (id) => {
  return baseURLInstance.get(`/api/traits/${id}/`);
};

const getTraitsByCrop = (cropID) => {
  return baseURLInstance.get(`/api/crops/${cropID}/traits/`);
};

const getPhenoTypesByCrop = (cropID) => {
  return baseURLInstance.get(`/api/crops/${cropID}/phenotypes/`);
};

const getLinePhotos = (lineID) => {
  return baseURLInstance.get(`/api/lines/${lineID}/linephotos/`);
};

const getFilterPhenotypes = (cropID) => {
  return baseURLInstance.get(`/api/crops/${cropID}/filterphenotype/`);
};

const getRequest = {
  get: async function (url, params) {
    try {
      const response = await baseURLInstance.get(url, { params });
      console.log(response);
      return response.data;
    } catch (error) {
      console.log("error making GET request: ", error);
      throw error;
    }
  },
};

const apiCalls = {
  getCrops,
  getCrop,
  getLines,
  getLine,
  getTraits,
  getTrait,
  getPhenoTypesByCrop,
  getLinePhotos,
  getFilterPhenotypes,
  getTraitsByCrop,
  getRequest,
  baseURLInstance,
  baseURL,
  reAuthenticateURLInstance,
};

export default apiCalls;
